<template>
  <div class="arrow-box grow">
    <img src="~assets/images/common/common_arrow.png" class="arrow arrow-1">
    <img src="~assets/images/common/common_arrow.png" class="arrow arrow-2">
    <img src="~assets/images/common/common_arrow.png" class="arrow arrow-3">
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
$arrow-size: 17px;
$arrow-animation-speed: 1.5s;

.arrow-box {
  position: relative;
  height: $arrow-size;
}

.arrow {
  width: $arrow-size;
  height: $arrow-size;
  position: absolute;
  top: 0;
  animation: arrow-anim $arrow-animation-speed normal linear backwards infinite;

  &.arrow-2 {
    animation-delay: 0.2s;
  }

  &.arrow-3 {
    animation-delay: 0.4s;
  }
}

@keyframes arrow-anim {
  0% {
    left: 0;
    opacity: 0;
  }

  50% {
    left: calc(100% - $arrow-size);
    opacity: 1;
  }

  100% {
    left: calc(100% - $arrow-size);
    opacity: 0;
  }
}
</style>
